<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="filter_by">{{ $t('group_by') }}</label>
                <select name="" id="filter_by" v-model="filters.filter_by" type="text" class="custom-select">
                  <option v-for="(row, index) in period_list" :value="row.slug" :key="'period'+index">{{ row.title }}</option>
                </select>
              </div>

              <div class="form-group col-md-6 mb-5">
                <label>{{ $t('expenses_income_transaction.currency') }}</label>
                <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('expenses_income_transaction.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('expenses_income_transaction.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.doc_type') }}</label>
                <select name="" id="status" v-model="filters.doc_type" @input="changeTypeObj" class="custom-select">
                  <option v-for="row in doc_type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
              </div>


              <template v-if="filters.doc_type == 'Customer'">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('expenses_income_transaction.customer') }}</label>
                      <multiselect
                          id="customer_id"
                          v-model="doc_type_obj"
                          :placeholder="$t('expenses_income_transaction.customer')"
                          label="fullname"
                          track-by="id"
                          :options="customers"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getCustomers($event)">
                      </multiselect>
                </div>
              </template>
              <template v-if="filters.doc_type == 'Supplier'">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('expenses_income_transaction.supplier') }}</label>
                      <multiselect
                          v-model="doc_type_obj"
                          :placeholder="$t('expenses_income_transaction.supplier')"
                          label="name"
                          track-by="id"
                          :options="suppliers"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getSuppliers($event)">
                      </multiselect>
                </div>
              </template>
              <template v-if="filters.doc_type == 'Employee'">
                <div class="col-lg-6 mb-5">
                  <label for="employee_id">{{ $t('expenses_income_transaction.employee') }}</label>
                    <multiselect v-model="doc_type_obj"
                                 :placeholder="$t('expenses_income_transaction.employee')"
                                 label="full_name"
                                 track-by="id"
                                 :options="employees"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @search-change="getEmployees($event)">
                    </multiselect>

                </div>
              </template>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.payment_method') }}</label>
                <select name="" id="payment_method" v-model="filters.payment_method" class="custom-select">
                  <option v-for="row in payment_methods" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
              </div>
              <div class="col-lg-6 mb-5" v-if="payment_method_type == 'bank'">
                <label>{{ $t('expenses_income_transaction.bank') }}</label>
                  <multiselect v-model="bank"
                               :placeholder="$t('expenses.bank')"
                               label="name"
                               track-by="id"
                               :options="banks"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="useBank"
                               @search-change="getBanks($event)">
                  </multiselect>
              </div>
              <div class="col-lg-6 mb-5" v-else-if="payment_method_type == 'treasury'">
                <label>{{ $t('expenses_income_transaction.treasury') }}</label>
                  <multiselect v-model="treasury"
                               :placeholder="$t('expenses.treasury')"
                               label="name"
                               track-by="id"
                               :options="treasuries"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="useTreasury">
                  </multiselect>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.class') }}</label>
                <select name="" id="payment_method" v-model="filters.class" class="custom-select">
                  <option :value="null" >{{ $t('all') }}</option>
                  <option :value="1" >{{ $t('expenses_income_transaction.doc_in') }}</option>
                  <option :value="2">{{ $t('expenses_income_transaction.doc_out') }}</option>
                </select>
              </div>

              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">
        <b-tabs content-class="mt-3">

          <b-tab :title="$t('details')" active>
            <div class="mt-3">
              <div class="row mb-10">
                <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                  <h4 class="text-center">{{ $t('MENU.income_expenses_report') }}</h4>
                  <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                </div>
              </div>
              <div class="row mb-10">
                <div class="col-12" v-if="data_report_details">
                  <div class="table-responsive">
                    <div class="row justify-content-end p-4">
                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                        <download-excel
                            class="dropdown-item"
                            :fetch="accountingDetailsExport"
                            :fields="json_fields_details"
                            :name="$t('MENU.income_expenses_report') + '.xls'">
                          <i class="la la-file-excel"></i>{{ $t('excel') }}
                        </download-excel>
                        <button class="dropdown-item" @click="printData('summaryTableDetails')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="summaryTableDetails">
                      <thead>
                      <tr>
                        <th>{{ $t('id') }}</th>
                        <th>{{ $t('expenses_income_transaction.code') }}</th>
                        <th>{{ $t('expenses_income_transaction.date') }}</th>
                        <th>{{ $t('expenses_income_transaction.type') }}</th>
                        <th>{{ $t('expenses_income_transaction.type_name') }}</th>
                        <th>{{ $t('expenses_income_transaction.payment_method') }}</th>
                        <th>{{ $t('expenses_income_transaction.doc_type_lable') }}</th>
                        <th>{{ $t('expenses_income_transaction.doc_type') }}</th>
                        <th>{{ $t('expenses_income_transaction.class') }}</th>
                        <th>{{ $t('expenses_income_transaction.currency') }}</th>
                        <th>{{ $t('expenses_income_transaction.amount') }}</th>

                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="(row, index) in data_report_details">
                        <tr :key="'a'+index">
                          <td colspan="11">{{ row.label }}</td>
                        </tr>
                        <tr v-for="(row2, index2) in row.details" :key="'a'+getRandom()+index2">
                          <td>{{ row2.id }}</td>
                          <td>{{ row2.code }}</td>
                          <td>{{ row2.expenses_date }}</td>
                          <td>{{ row2.type }}</td>
                          <td>{{ row2.type_name }}</td>
                          <td>{{ row2.payment_method_name }}</td>
                          <td>{{ row2.doc_type_lable }}</td>
                          <td>{{ row2.doc_type_name }}</td>
                          <td>{{ row2.class_name }}</td>
                          <td>{{ row2.currency_name }}</td>
                          <td>{{ row2.amount }}</td>
                        </tr>
                        <tr :key="'b'+index">
                          <td colspan="10">{{ $t('income_report.total') }}</td>
                          <td>{{ row.total_amount }}</td>
                        </tr>
                      </template>

                      </tbody>
                      <tfoot>
                      <tr>
                        <td colspan="10"><b>{{ $t('income_report.total') }}</b></td>
                        <th>{{ details_total_sum ? details_total_sum : 0 }}</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </b-tab>

          <!-- <b-tab> -->
          <!-- </b-tab> -->
        </b-tabs>
        <div class="text-center">
          <button class="btn btn-warning" @click="loadMore">
            <p class="mb-0">
              <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
              {{ $t('load_more') }}
            </p>
          </button>
        </div>

      </div>
    </div>
    <!--end::supplier-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-income-expenses-report",
  components: {},
  data() {
    return {
      mainRoute: '/reports/expenses_incomes_report/report',
      mainRouteDependency: 'base/dependency',

      currency_name: null,
      currencies: [],
      treasuries: [],
      period_list: [
        {title: this.$t('income_report.daily'), slug: 'daily'},
        {title: this.$t('income_report.weekly'), slug: 'weekly'},
        {title: this.$t('income_report.monthly'), slug: 'monthly'},
        {title: this.$t('income_report.yearly'), slug: 'yearly'},
      ],

      data_line: [],
      data_report_details: [],
      details_total_sum: 0,

      filters: {
        from_date: null,
        to_date: null,
        doc_type_id: null,
        currency_id: null,
        doc_type: null,
        filter_by: 'daily',
        payment_method: null,
        type_id: null,
        type_type: null,
        data_type: 'details',
        class: null,
      },
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,

      treasury: null,


      doc_type_list: [],
      doc_type_obj: null,
      suppliers: [],
      customers: [],
      employees: [],
      banks: [],
      payment_methods: [],
      bank: null,
      payment_method_type: null,
      out_currency_id: null,

    }
  },
  computed: {
    json_fields_details: function () {
      let fields = {};

      fields[this.$t('id')] = 'id';
      fields[this.$t('expenses_income_transaction.code')] = 'code';
      fields[this.$t('expenses_income_transaction.date')] = 'expenses_date';
      fields[this.$t('expenses_income_transaction.type')] = 'type';
      fields[this.$t('expenses_income_transaction.type_name')] = 'type_name';
      fields[this.$t('expenses_income_transaction.payment_method')] = 'payment_method_name';
      fields[this.$t('expenses_income_transaction.doc_type_lable')] = 'doc_type_lable';
      fields[this.$t('expenses_income_transaction.doc_type')] = 'doc_type_name';
      fields[this.$t('expenses_income_transaction.class')] = 'class_name';
      fields[this.$t('expenses_income_transaction.currency')] = 'currency_name';
      fields[this.$t('expenses_income_transaction.amount')] = 'amount';

      return fields;
    },


  },
  watch: {
    doc_type_obj: function (val) {
      if (val && val.id)
        this.filters.doc_type_id = val.id;
      else
        this.filters.doc_type_id = null;
    },
    'filters.payment_method': function (val) {
      this.payment_method_type = null;
      if (val){
        let _method = this.payment_methods.find(row => row.id == val);
        if (_method){
          this.payment_method_type = _method.cash_type;
        }
      }
    },
  },
  beforeMount() {
    this.getTreasuries();
    this.getAuthUser();
    this.getCurrencies();
    this.docTypeListInit();
    this.getPaymentMethods();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.accounting_reports'), route: '/reports/accounting-reports'}, {title: this.$t("MENU.income_expenses_report")}]);
  },
  methods: {

    accountingDetailsExport() {
      this.setReportLog('excel', 'income and expenses report');
      let _footer = {
        id: this.$t('income_report.total') + ` (${this.currency_name})`,
        code: '',
        expenses_date: '',
        type: '',
        type_name: '',
        payment_method_name: '',
        doc_type_lable: '',
        doc_type_name: '',
        class_name: '',
        currency_name: '',
        amount: this.details_total_sum,
      };
      let _data = [];
      this.data_report_details.forEach((row) => {
        _data.push({
          id: row.label,
          code: '',
          expenses_date: '',
          type: '',
          type_name: '',
          payment_method_name: '',
          doc_type_lable: '',
          doc_type_name: '',
          class_name: '',
          currency_name: '',
          amount: '',
        });
        row.details.forEach((sub_row) => {
          _data.push({
            id: sub_row.id,
            code: sub_row.code,
            expenses_date: sub_row.expenses_date,
            type: sub_row.type,
            type_name: sub_row.type_name,
            payment_method_name: sub_row.payment_method_name,
            doc_type_lable: sub_row.doc_type_lable,
            doc_type_name: sub_row.doc_type_name,
            class_name: sub_row.class_name,
            currency_name: sub_row.currency_name,
            amount: sub_row.amount,
          });
        });
        _data.push({
          id: this.$t('income_report.total') + ` (${this.currency_name})`,
          code: '',
          expenses_date: '',
          type: '',
          type_name: '',
          payment_method_name: '',
          doc_type_lable: '',
          doc_type_name: '',
          class_name: '',
          currency_name: '',
          amount: row.total_amount,
        });
      });
      _data.push(_footer);

      return _data;
    },

    getRandom() {
      return Math.floor(Math.random() * 10000);
    },
    doFilter() {
      this.getReportDetails();
      // this.getReportChart();
    },
    resetFilter() {
      this.filters = {
        from_date: null,
        to_date: null,
        doc_type_id: null,
        currency_id: this.out_currency_id,
        doc_type: null,
        filter_by: 'daily',
        payment_method: null,
        type_id: null,
        type_type: null,
        data_type: 'details',
        class: null,
      };
      this.treasury = null;
      this.doc_type_obj = null;
      this.bank = null;
      this.payment_method_type = null;
      this.getReportDetails();
    },
    getAuthUser() {
      ApiService.get(`/get_auth`).then((response) => {
        this.currency_name = response.data.data.currency_name;
      });
    },

    getTreasuries() {
      ApiService.get(this.mainRouteDependency + "/treasuries").then((response) => {
        this.treasuries = response.data.data;
      });
    },

    loadMore() {
      this.page = this.page + 1;

      this.getReportDetails();
      // this.getReportChart();
    },

    /*****
     * details
     */

    getReportDetails() {
      ApiService.get(this.mainRoute, {params: {...this.filters, data_type: 'details', page: (this.page ? this.page : 1)}}).then((response) => {
        this.data_report_details = response.data.data;
        this.details_total_sum = response.data.sum_total_amount ? response.data.sum_total_amount : 0;
      }).then().catch((error) => {
        this.$errorAlert(error)
      });
    },
    // getReportChart() {
    //   ApiService.get(this.mainRoute, {params: {...this.filters, data_type: 'chart', page: (this.page ? this.page : 1)}}).then((response) => {
    //     let _labels = [], _data = [];
    //     response.data.data.map((row) => {
    //       _labels.push(row.label);
    //       _data.push(row.total);
    //     });
    //     this.data_line = {labels: _labels, data: _data};
    //   });
    // },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
        let _currency = response.data.data.find(row => row.code == 'SAR');
        if (_currency){
          this.filters.currency_id = _currency.id;
          this.out_currency_id = _currency.id;
        }
      });
    },
    printData(tableId) {
      this.setReportLog('pdf', 'income and expenses report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById(tableId + 'Title');
      let newWin = window.open("");
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        newWin.close();
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    },

    docTypeListInit(){
      this.doc_type_list = [];
      if(this.$can('customers.list')){
        this.doc_type_list.push({id: 'Customer', title: this.$t('expenses_income_transaction.customer')})
      }
      if(this.$can('suppliers.list')){
        this.doc_type_list.push({id: 'Supplier', title: this.$t('expenses_income_transaction.supplier')})
      }
      if(this.$can('employees.list')){
        this.doc_type_list.push({id: 'Employee', title: this.$t('expenses_income_transaction.employee')})
      }

      this.doc_type_list.push({id: 'Others', title: this.$t('expenses_income_transaction.others')})


    },

    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },

    async getCustomers(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      }
    },
    async getEmployees(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/employees`, {params: {filter: filter}}).then((response) => {
          this.employees = response.data.data;
        });
      }
    },
    getBanks(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/banks`, {params: {filter: filter}}).then((response) => {
          this.banks = response.data.data;
        });
      } else {
        this.banks = [];
      }
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    useBank(obj) {
      this.filters.type_id = obj.id;
      this.filters.type_type = 'Bank';

    },
    useTreasury(obj) {
      this.filters.type_id = obj.id;
      this.filters.type_type = 'Treasuries';

    },
    changeTypeObj() {
      this.doc_type_obj = null;
      this.filters.doc_type_id = null;
    },
  },
};
</script>
